<template>
    <div>
  <!-- <a href="javascript:void(0);" onclick="getReports();"
    ><i
      class="fas fa-file-pdf fs16"
      title="Create Group Report for {{result['groupID']}}"
    ></i>
    Report</a
  > -->

<!--  <addField
    path="serve.php?f=testing&f2=reporting"
    :additionalAttributes="additionalAttributes"
    :dialogAttributes="{}"
    :showDialog="openDialogFlag"
    @dialog-closed="openDialogFlag == false"
    ><div class="mr-4"><v-icon style="margin-right: 9px!important;">mdi-file-pdf-box</v-icon>{{language.componentLabel.labelReport}}</div></addField
  >-->
    </div>
</template>
<script>
//import addField from "@/commonComponents/addField.vue";
export default {
  data() {
    return { openDialogFlag: false };
  },
  props: ["result", "additional"],
  components: {
  //  addField
  },
  computed: {
    additionalAttributes() {
      let selected = Object.keys(this.additional.selectedRows);
      return {
        n_testgroup_id: this.result["groupID"],
        selected: selected,
        updateTable: "false",
        function: "getInsertedInstantReporting",
      };
    },
  },
  // methods: {
  //   getReports() {
  //     let selected = Object.keys(this.additional.selectedRows);

  //     if (selected.length > 0) {
  //       AddField("", "", "serve.php?f=testing&f2=reporting", {
  //         n_testgroup_id: this.result["groupID"],
  //         selected: selected,
  //         updateTable: "false",
  //         function: "getInsertedInstantReporting",
  //       });
  //     } else {
  //       showWarn();
  //     }
  //   },
  // },
};
</script>